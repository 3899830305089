import React from "react";
import styled from "styled-components";
import monei_pay_1_en from "images/monei_pay_1_en.png";
import monei_pay_2_en from "images/monei_pay_2_en.png";
import monei_pay_3_en from "images/monei_pay_3_en.png";
import monei_pay_4_en from "images/monei_pay_4_en.png";
import monei_pay_1_es from "images/monei_pay_1_es.png";
import monei_pay_2_es from "images/monei_pay_2_es.png";
import monei_pay_3_es from "images/monei_pay_3_es.png";
import monei_pay_4_es from "images/monei_pay_4_es.png";
import { LanguageEnum } from "../../../locales/types";
import { useI18next } from "gatsby-plugin-react-i18next";
import { ScreenSizes } from "../../../types/responsive";

const Screenshots = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  overflow: visible;
  max-width: 1170px;
  margin: 0 auto 100px;
  grid-gap: 3%;
  
  @media (max-width: ${ScreenSizes.sm}) {
    grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: skew(0, -15deg);
    top: 15%;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(40deg, #12e0c2 0%, #aab4e9 90%);
    z-index: -1;
    height: 65%;
  }
`;

const Screenshot = styled.img`
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 260px;
  @media (max-width: ${ScreenSizes.sm}) {
    max-width: 272px;
  }
`;

const moneiPayScreenshots = {
  en: [monei_pay_1_en, monei_pay_2_en, monei_pay_3_en, monei_pay_4_en],
  es: [monei_pay_1_es, monei_pay_2_es, monei_pay_3_es, monei_pay_4_es],
}

export const HorizontalScreenshots: React.FC<{screenshots?: {en: string[], es: string[]}}> = ({screenshots = moneiPayScreenshots}) => {
  const {language} = useI18next();
  const isSpanish = [LanguageEnum.es, LanguageEnum.ca].includes(language as LanguageEnum);
  const monei_pay_1 = isSpanish ? screenshots.es[0] : screenshots.en[0];
  const monei_pay_2 = isSpanish ? screenshots.es[1] : screenshots.en[1];
  const monei_pay_3 = isSpanish ? screenshots.es[2] : screenshots.en[2];
  const monei_pay_4 = isSpanish ? screenshots.es[3] : screenshots.en[3];

  return (
    <Screenshots>
      <Screenshot src={monei_pay_1} />
      <Screenshot src={monei_pay_2} />
      <Screenshot src={monei_pay_3} />
      <Screenshot src={monei_pay_4} />
    </Screenshots>
  );
};
