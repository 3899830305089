import styled from 'styled-components';
import {ScreenSizes} from '../../../types/responsive';
import {StyledDiv} from '../StyledSx';

export const MainTitleContent = styled(StyledDiv)`
  max-width: 500px;
  @media (max-width: ${ScreenSizes.md}) {
    padding-top: 0;
    max-width: 100%;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding-top: 10px;
  }
`;
