import styled from 'styled-components';
import {ScreenSizes} from '../../../types/responsive';
import React from 'react';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {LanguageEnum} from '../../../locales/types';
import scanme_qr_group_en from 'images/scanme_qr_group_en.svg';
import scanme_qr_group_es from 'images/scanme_qr_group_es.png';

const QrGroupContainer = styled.div`
  margin: 0;
  text-align: center;
  img {
    position: relative;
    max-height: 650px;
    height: 100%;

    @media (max-width: ${ScreenSizes.md}) {
      max-height: 500px;
    }
    @media (max-width: ${ScreenSizes.sm}) {
      display: none;
    }
  }
`;

export const QrGroup: React.FC = () => {
  const {language} = useI18next();
  const isSpanish = [LanguageEnum.es, LanguageEnum.ca].includes(language as LanguageEnum);
  const scanme_qr_group = isSpanish ? scanme_qr_group_es : scanme_qr_group_en;

  return (
    <QrGroupContainer>
      <img src={scanme_qr_group} alt="MONEI pay QR" />
    </QrGroupContainer>
  );
};
