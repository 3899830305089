import React from "react";
import { StyledImg, StyledP } from "../StyledSx";
import { Trans } from "gatsby-plugin-react-i18next";
import { Flex } from "../Flex";
import applePay from "images/applepay-logo-small.svg";
import googlePay from "images/googlepay-logo-small.svg";
import bizum from "images/bizum_payment_gateway.svg";
import paypal from "images/paypal-logo-small.svg";

export type Logo = { src: string, height: string }

const pmLogos: Logo[] = [{src: applePay, height: '21px'}, {src: googlePay, height: '21px'}, {src: bizum, height: '21px'}, {src: paypal, height: '21px'}];

export const CompatibleWith: React.FC<{logos?: Logo[]; title?: JSX.Element, logoHeight?: string}> = ({
  title = <Trans>Compatible with</Trans>,
  logos = pmLogos
}) => {
  return (
    <Flex direction="column">
      <StyledP sx={{color: '#74858E', marginTop: '16px'}}>{title}</StyledP>
      <Flex sx={{gap: {all: '30px', xs: '12px 24px'}, flexWrap: 'wrap', alignItems: 'center'}}>
        {logos.map((logo) => (
          <StyledImg src={logo.src} sx={{height: logo.height}}/>
        ))}
      </Flex>
    </Flex>
  );
};
