import styled from 'styled-components';
import {ScreenSizes} from '../../../types/responsive';

export default styled.div`
  position: relative;
  display: flex;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -8.9%;
    height: 1256px;
    width: 1225px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1214, -91) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: ${ScreenSizes.lg}) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 459, -315);
    left: 50%;
    top: -3%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: ${ScreenSizes.md}) {
      content: none;
    }
  }

  @media (max-width: 1280px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    margin-bottom: 48px;
  }
`;
