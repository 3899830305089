// i18next-extract-mark-ns-start pos-systems-for-hair-salon

import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {List} from 'components/List';
import 'keen-slider/keen-slider.min.css';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Images
import monei_pay_qr_en from 'images/monei_pay_qr_en.png';
import monei_pay_qr_es from 'images/monei_pay_qr_es.png';
import {Flex} from 'components/Flex';
import React from 'react';
import {DownloadOnAppleStore, IosDownloadLink} from 'components/DownloadOnAppleStore';
import {LanguageEnum} from '../../../locales/types';
import {AndroidDownloadLink, DownloadOnGooglePlay} from 'components/DownloadOnGooglePlay';
import Bold from 'components/Bold';
import {MoneiVideoSection} from 'components/landings/MoneiVideoSection';
import {CompatibleWith} from 'components/monei-pay/CompatibleWith';
import IndexBackground from 'components/monei-pay/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {StyledDiv} from 'components/StyledSx';
import {QrGroup} from 'components/monei-pay/QrGroup';
import {HorizontalScreenshots} from 'components/monei-pay/HorizontalScreenshots';
import {InternalPageLink} from 'components/links/Pages';
import {SupportLink} from 'components/links/Support';
import {MainTitleContent} from 'components/monei-pay/MainTitleContent';

const MoneiPay: React.FC<PageProps> = () => {
  const {t, language} = useI18next();
  const scanToPay = language === LanguageEnum.en ? monei_pay_qr_en : monei_pay_qr_es;
  return (
    <>
      <SEO
        title={t('POS System for hair and beauty salons')}
        description={t(
          'Save time and money while avoiding bulky and unreliable POS systems for hair and beauty salons by accepting payments from your phone. Get MONEI Pay ››'
        )}
      />

      <IndexBackground>
        <Content>
          <Section columnLg>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>Discover the best POS system for hair salons</Trans>
              </SectionHeader>
              <Trans parent="p">
                Simplify payments, concentrate on clients, and forget about your bulky, expensive, and unreliable hair
                salon POS. Instead, charge your customers from your mobile phone using digital QR codes. Choose the best
                POS system for your hair or beauty salon — choose <Bold>MONEI Pay.</Bold>
              </Trans>
              <Trans parent="p">
                Install the app on as many mobile devices as you need (Android or iOS) to securely and conveniently
                accept
                payments in your salon, at events, or on the go.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Get MONEI Pay Now</Trans>
                </SignUpButton>
                <ContactSalesButton/>
                <CompatibleWith/>
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection/>
          </Section>
        </Content>
      </IndexBackground>
      <Content sx={{marginTop: {md: '20px'}}}>
        <Section centered sx={{paddingTop: '20px'}}>
          <SectionImage
            style={{width: '455px'}}
            mobileWidth={370}
            src={scanToPay}
            alt="Scan to Pay"
            title="Scan to Pay"
            className="hide-on-mobile"
          />
          <div>
            <SectionHeader underline style={{marginTop: '10px'}}>
              <Trans>Accept payments in your hair salon without a POS</Trans>
            </SectionHeader>
            <Trans parent="p">
              The best POS system for hair salons and beauty salons is not a traditional card terminal. Get a cheaper
              and more reliable option while keeping your transactions secure through <InternalPageLink slug="qr">QR
              code
              payments</InternalPageLink>.
            </Trans>
            <Trans parent="p">
              <Bold><InternalPageLink slug="monei-pay">MONEI Pay’s</InternalPageLink> hair salon POS software lets you:</Bold>
            </Trans>
            <List>
              <Trans parent="li">
                Save money on hardware and transaction fees with a payment solution that’s <InternalPageLink
                slug="pricing">50%
                cheaper</InternalPageLink> than a traditional salon POS system
              </Trans>
              <Trans parent="li">
                Accept many payment methods:{' '}
                <InternalPageLink slug="payment-methods/credit-cards">credit cards</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>, {' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>{' '}
                (in Spain){' '}
              </Trans>
              <Trans parent="li">
                Offer a <BlogLink slug="frictionless-payments">seamless payment</BlogLink>{' '}
                experience
              </Trans>
              <Trans parent="li">
                <SupportLink slug="8797620433937">Personalize</SupportLink> your payment page
              </Trans>
              <Trans parent="li">
                Get real-time transaction history reports
              </Trans>
              <Trans parent="li">Equip all your staff to accept payments at their chair or table</Trans>
              <Trans parent="li">Charge from anywhere, like weddings, photo shoots, clients’ homes, theaters, and
                more</Trans>
            </List>
            <Trans parent="p"><Bold>👇 Download your mobile payment app today.</Bold></Trans>
            <Flex>
              <div style={{marginRight: '16px'}}>
                <DownloadOnAppleStore/>
              </div>
              <DownloadOnGooglePlay/>
            </Flex>
          </div>
        </Section>
      </Content>
      <Content>
        <Section textAlign="center">
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SectionHeader style={{fontSize: '2rem'}}>
              <Trans>
                How does <Bold>MONEI Pay</Bold> for hair and beauty salons work?
              </Trans>
            </SectionHeader>
            <Trans parent="p">
              <SupportLink slug="/articles/4417692136977">
                MONEI Pay
              </SupportLink>{' '}
              is a user friendly, powerful hair salon POS alternative for beauty salons that lets you acccept instant
              payments{' '}
              <SupportLink slug="/sections/360005899978">
                using digital QR codes
              </SupportLink>
              . All you have to do is open the app on your mobile device, enter the payment amount, and have your client
              scan the QR code with their smartphone. Then they can select their preferred payment method and complete
              the payment.
            </Trans>
            <SignUpButton variant="light">
              <Trans>Try MONEI Pay</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <Content fullWidth>
        <HorizontalScreenshots/>
      </Content>

      <Content>
        <Section reverseOnMobile centered sx={{paddingTop: '40px'}}>
          <QrGroup/>
          <div>
            <SectionHeader underline>
              <Trans>Start accepting salon payments from your <br/>phone or tablet</Trans>
            </SectionHeader>
            <Trans parent="p">
              Create your MONEI Pay account in a few simple steps:
            </Trans>
            <List>
              <Trans parent="li">Sign up for MONEI</Trans>
              <Trans parent="li">Download
                the <AndroidDownloadLink>Android</AndroidDownloadLink> or <IosDownloadLink>iOS</IosDownloadLink> mobile
                payment app on your preferred number of devices</Trans>
              <Trans parent="li">Add users</Trans>
              <Trans parent="li">Start generating digital QR code payments for your hair or beauty salon </Trans>
              <Trans parent="li">Get paid!</Trans>
            </List>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                👈 <Bold>Scan any QR code to test the payment experience.</Bold>
              </Trans>
            </StyledDiv>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
    </>
  );
};

export default MoneiPay;

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {ns: {in: ["common", "pos-systems-for-hair-salon"]}, language: {eq: $language}}
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
